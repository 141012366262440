.card {
  border: none;
}

.card-header-customer,
.card-footer-customer {
  background-color: #fff;
}

.card-diviser {
  background-color: #f0f2f5;
  padding: 1px;
}

.pagination-customer .page-link.active,
.active > .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: $blue;
  border-color: $blue;
}

.pagination-customer .page-link,
.active > .page-link {
  color: #000 !important;
}

.pagination-select-customer {
  border: none;
  background-color: transparent;
  color: #4b4b4b;
}

.nav-link-customer {
  text-decoration: none;
  color: #ffff;

  display: flex;
  justify-content: center;
  align-items: center;

  em {
    margin: none;
    padding: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }
}
.nav-link-customer:hover {
  color: #f0f2f5;
}

.active {
  color: #000 !important;
  background: none !important;
  font-weight: 600;
  background-color: #f0f2f5 !important;
}

#menu {
  width: 100%;

  li {
    width: 100%;
    border-radius: 0;

    a {
      width: 100%;
      border-radius: 0;
      display: flex;
      justify-content: flex-start;
      padding: 0.8rem !important;
    }
  }
}

#sidebar {
  margin: 0 !important;
  padding: 0 !important;
}

* {
	font-family: 'Tahoma', sans-serif !important;
}

.text-primary, title, p, h5 {
	color: $dark-grey-transp !important;
}

.ngb-dp-weekday {
	font-size: 11px;
}

.spinner-border {
  color: $blue;
}

@media screen and (max-width: 1400px) {
  .innerHTML {
    font-size: 1.2rem;
  }
  .text-primary {
    font-size: 1.5rem;
  }

  * {
    font-size: 0.9rem;
  }
  strong {
    font-size: 1rem;
  }
  h4 {
    font-size: 1.2rem;
  }
}


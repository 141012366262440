@font-face {
  font-family: 'UniNeueThin';
  src: url('/assets/fonts/UniNeue/UniNeue-Trial-Thin.woff2') format('woff2'),
       url('/assets/fonts/UniNeue//UniNeue-Trial-Thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UniNeueLight';
  src: url('/assets/fonts/UniNeue/UniNeue-Trial-Light.woff2') format('woff2'),
       url('/assets/fonts/UniNeue/UniNeue-Trial-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'UniNeueBook';
  src: url('/assets/fonts/UniNeue/UniNeue-Trial-Book.woff2') format('woff2'),
       url('/assets/fonts/UniNeue/UniNeue-Trial-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tahoma';
  src: url('/assets/fonts/Tahoma/tahoma.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}


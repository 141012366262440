::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 20px;
  border: 3px solid #fff;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #fff #f0f2f5;
}

@media (min-width: 1920px) {
  .container-fluid {
    height: 93vh;
  }
}

@media (max-width: 1900px) {
  .container-fluid {
    height: 84vh;
  }
}

:root {
	--blue: #1db4ff;
	--blue-transp: #1db4ff6e;
	--off-white: #d8e5d3;
	--off-white-transp: #d8e5d394;
	--off-white-transp-1: #d8e5d341;
	--roxo: #7b18dd;
  --roxo-transp: #7b18dd81;
	--marinho: #26539c;
	--dark-grey: #100f2c;
	--dark-grey-transp: #100f2cc4;
	--verde-citrico: #cbdb2a;
  --verde-citrico-transp: #ccdb2a79;
	--gradiente-darkgray-blue: linear-gradient(180deg, rgba(216, 229, 211, 1) 17%, rgba(156, 204, 218, 1) 75%, rgba(89, 176, 226, 1) 98%);
}
$blue: #1db4ff;
$blue-transp: #1db4ff6e;
$off-white: #d8e5d3;
$off-white-transp: #d8e5d394;
$off-white-transp-1: #d8e5d341;
$roxo: #7b18dd;
$marinho: #26539c;
$dark-grey: #100f2c;
$dark-grey-transp: #100f2cc4;
$verde-citrico: #cbdb2a;
$verde-citrico-transp: #ccdb2a79;
$gradiente-darkgray-blue: linear-gradient(180deg, rgba(216, 229, 211, 1) 17%, rgba(156, 204, 218, 1) 75%, rgba(89, 176, 226, 1) 98%);

table {
  td {
    color: #808080;
  }
  th {
    color: #4b4b4b;
  }

  td,
  span {
    cursor: pointer;
  }
}

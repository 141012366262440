/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';
@import './assets/styles/theme';
@import './assets/styles/fonts';
@import './assets/styles/button.customer';
@import './assets/styles/card.customer';
@import './assets/styles/table.customer';
@import './assets/styles/pagination.customer';
@import './assets/styles/form.customer';
@import './assets/styles/modal.customer';
@import './assets/styles/scrollbar.customer';
@import './assets/styles/calendar.customer';
@import './assets/styles/customer';
@import './assets/styles/sidebar.customer';
@import 'ngx-bootstrap/datepicker/bs-datepicker';
.dp-hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}
.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.custom-day.focused {
  background-color: #e6e6e6;
}
.custom-day.range,
.custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: white;
}
.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}

.custom-alert {
  color: red;
  font-size: 0.7rem;
}
